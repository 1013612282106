<template>
  <van-pull-refresh v-model="isLoading" pulling-text="Tarik ke bawah untuk refresh..." loosing-text="Lepaskan untuk refresh..."  success-text="Refresh Berhasil" @refresh="onRefresh">
    <div class="loan-status">
      <BaseHeaderBar />
      <div v-if="orderStatus === 1 || orderStatus === 2 || orderStatus === 10">
        <img class="top-img" src="@/assets/img/loan-status1.png">
        <p class="intro">Dalam proses peninjauan</p>
      </div>

      <div v-else-if="orderStatus === 3" class="loan-status3">
        <img class="top-img" src="@/assets/img/loan-status3.png">
        <p class="intro">Dalam proses pencairan</p>
        <div class="info">
          <p>Jumlah yang diterima:Rp{{ loanInfo.ardrrivalAmount }}</p>
          <p>Nama bank penerima:{{ loanInfo.ardrrivalBank }}</p>
          <p>Nomor rekening bank penerima:{{ loanInfo.ardrrivalBankcard }}</p>
        </div>
      </div>

      <div v-else-if="orderStatus === 4" class="loan-status4">
        <img class="top-img" src="@/assets/img/loan-status4.png">
        <p class="intro">Anda tidak dapat melakukan pinjaman saat ini</p>
        <p class="detail">Untuk saat ini Anda tidak memenuhi persyaratan. Pastikan untuk selalu mempertahankan riwayat kredit yang baik.</p>
      </div>

      <div v-else-if="orderStatus === 5" class="loan-status5">
        <div class="repayment-content">
          <p class="p1">Menunggu pembayaran</p>
          <p class="p2">Harap melakukan pembayaran sebelum tanggal {{ loanInfo.bidrllDetails.ardrrivalTime }}</p>
          <div class="repayment-record">
            <div class="head">
              <div class="overdue">Sudah terlambat {{ loanInfo.bidrllDetails.ovdrerdueDay }} hari</div>
              <div class="detail" @click="goRepaymenDetail">Rincian</div>
            </div>
            <p class="label">Pelunasan Total:Rp{{ loanInfo.bidrllDetails.fudrllrepaymentAmount }}</p>
            <p class="label">Perpanjangan Durasi:Rp{{ loanInfo.bidrllDetails.exdrhibitionrepaymentaAount }}</p>
          </div>
        </div>
        <button type="button" class="full-repayment-btn" @click="goRepay(1)">Pelunasan</button>
        <button type="button" class="installment-repayment-btn" @click="goRepay(2)">Perpanjangan</button>
      </div>

      <div v-else-if="orderStatus === 0 || orderStatus === 6 || orderStatus === 7" class="loan-status6">
        <p class="loan-title">Jumlah pinjaman</p>
        <div class="loan-amount-sort">
          <div ref="loanItem" v-for="(item, index) in loanInfo.mudrltipleLoanList" :key="index" :class="[item.loanStatus ? 'can-loan' : 'no-loan', { active: activeIndex === index } ]" @click="selectLoan(item, index)">
            <img :src="require(`@/assets/img/lock-icon${item.loanStatus ? '' : 1}.png`)" />
            <p>Rp{{ item.loanMoney }}</p>
          </div>
        </div>
        <template v-if="loanTimers && loanTimers.length">
          <p class="deadline-title">pinjaman {{ loanTimers[0] }} Hari</p>
          <div class="time">
            <img class="deadline-icon" src="@/assets/img/deadline-icon.png" />
            <p>{{ loanTimers[0] }} Hari</p>
          </div>
        </template>
        <template v-if="orderStatus === 0 || (orderStatus === 7 && loanInfo.redr_loan === 0)">
          <p class="deadline-title">Suku bunga tahunan</p>
          <div class="time">
            <img class="rate-icon" src="@/assets/img/rate-icon.png" />
            <p>{{ loanInfo.dadryRate }}</p>
          </div>
        </template>
        <button type="button" class="submit" @click="submit">Langsung Pengajuan</button>

        <van-dialog v-model="visible" :showConfirmButton="false" close-on-click-overlay>
          <template #title>
            <p class="rise-limit-title">Saya ingin menaikkan limit</p>
          </template>
          <h3 class="tip-title">Bagaimana cara menaikkan limit pinjaman</h3>
          <p class="tip">Mempertahankan kebiasaan baik dan selalu melunasi tepat waktu. Anda bisa mendapatkan kenaikan limit apabila menghindari perilaku keterlambatan pembayaran.</p>
          <button type="button" class="confirm-btn" @click="onCloseDialog()">Mengerti</button>
        </van-dialog>
      </div>

      <div v-else-if="orderStatus === 8" class="loan-status8">
        <img class="top-img" src="@/assets/img/loan-status8.png" />
        <p class="intro">Verifikasi KTP gagal</p>
        <p class="detail">Foto KTP yang Anda unggah tidak jelas, sehingga tidak dapat lolos evaluasi. Silakan unggah ulang.</p>
        <button type="button" class="modify-btn" @click="goAuthentication">Edit data</button>
      </div>

      <div v-else-if="orderStatus === 9" class="loan-status9">
        <img class="top-img" src="@/assets/img/loan-status9.png" />
        <p class="intro">Pencairan gagal</p>
        <p class="detail">Pencairan tidak dapat dilakukan karena data rekening bank Anda tidak tersedia. Silakan isi ulang.</p>
        <button type="button" class="modify-btn" @click="goBankInfo">Edit data</button>
        <van-dialog v-model="cancelLoanVisible" :showConfirmButton="false" close-on-click-overlay class="cancel-loan-dialog">
          <div class="cancel-loan-dialog-bg"></div>
          <h3 class="cancel-loan-dialog-title">Berhasil dibatalkan</h3>
          <p class="tip">Pinjaman Anda telah dibatalkan. Anda tidak perlu verifikasi data lagi jika ingin melakukan pinjaman.</p>
          <button type="button" class="confirm-btn" @click="onCloseDialog('cancelLoanVisible')">Mengerti</button>
        </van-dialog>
      </div>

      <div v-else-if="orderStatus === 12" class="loan-status12">
        <p class="deadline-title">Jumlah pinjaman</p>
        <div class="time">
          <img class="lock-icon" src="@/assets/img/lock-icon.png" />
          <p>Rp{{ loanInfo.lodranAmount }}</p>
        </div>
        <template v-if="loanInfo.avdrailablePeriod && loanInfo.avdrailablePeriod.length">
          <p class="deadline-title">pinjaman {{ loanInfo.avdrailablePeriod[0] }} Hari</p>
          <div class="time">
            <img class="deadline-icon" src="@/assets/img/deadline-icon.png" />
            <p>{{ loanInfo.avdrailablePeriod[0] }} Hari</p>
          </div>
        </template>
        <button type="button" class="withdraw-btn" @click="withdraw">Segera Tarik</button>
      </div>

      <van-dialog v-model="feeVisible" :showConfirmButton="false" close-on-click-overlay>
        <template #title>
          <p class="fee-title">Rincian Biaya</p>
        </template>
        <div class="fee-detail">
          <div>
            <p>Jumlah Pinjaman</p>
            <p>Rp{{ feeDetail.lodranCash }}</p>
          </div>
          <div>
            <p>Jumlah hari pinjaman</p>
            <p>{{ feeDetail.lodranTimes }} Hari</p>
          </div>
          <div>
            <p>Biaya Administrasi</p>
            <p>Rp{{ feeDetail.todrtalCost }}</p>
          </div>
          <div>
            <p>Jumlah yang diterima</p>
            <p>Rp{{ feeDetail.ardrrivalCash }}</p>
          </div>
        </div>
        <div class="footer-btn">
          <button type="button" class="cancel-btn" @click="onCloseDialog('feeVisible')">Batal</button>
          <button type="button" class="ok-btn" @click="onOkFee">Yakin</button>
        </div>
      </van-dialog>
    </div>
    <template #loading>
      <span></span>
    </template>
  </van-pull-refresh>
</template>

<script>
import Vue from 'vue'
import { Dialog, Toast, PullRefresh } from 'vant'
import request from '@/utils/request'
import dialogVisible from '@/mixins/dialogVisible'
import webviewBack from '@/mixins/webviewBack'

Vue.use(Toast)
Vue.use(PullRefresh)

export default {
  name: 'Home',
  mixins: [dialogVisible, webviewBack],
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  data () {
    return {
      isLoading: false,
      cancelLoanVisible: false,
      feeVisible: false,
      orderStatus: '',
      loanInfo: {},
      feeDetail: {},
      activeIndex: -1,
      loanTimers: [],
      loanAmount: ''
    }
  },
  created () {
    this.getOrderStatus()
    this.closeWebview()
  },
  methods: {
    getOrderStatus (isRefresh) {
      request.post('homeDRInterface')
        .then(res => {
          this.orderStatus = res.ordrderStatus
          this.loanInfo = res
          if (res.ordrderStatus === 0 || res.ordrderStatus === 6 || res.ordrderStatus === 7) {
            this.loanAmount = res.lodranMaxAmount
            let index = -1
            res.mudrltipleLoanList.forEach((item, i) => {
              if (item.loanStatus) {
                index = i
              }
            })
            this.activeIndex = index
            this.selectedLoanItem = res.mudrltipleLoanList[index]
            this.loanTimers = this.initLoanTimers = res.mudrltipleLoanList[index].loanTimers || []
            this.$nextTick().then(() => {
              this.$refs.loanItem[index].scrollIntoView({ behavior: 'smooth', inline: 'center' })
            })
          }
          isRefresh && (this.isLoading = false)
        })
    },
    getFeeDetail (item, loanType) {
      const postData = {
        lodranCash: item.loanMoney,
        lodranTimers: item.loanTimers[0],
        lodranType: loanType
      }
      this.withdrawType = loanType
      request.post('indrterestrateBomb', postData)
        .then(res => {
          this.feeDetail = res
        })
    },
    goRepaymenDetail () {
      this.$router.push('/repaymenDetail')
    },
    goBankInfo () {
      if (this.loanInfo.badrnkStatus) {
        const postData = {
          apdrplicationAmount: this.loanInfo.lodranAmount,
          prdromotionChannel: 'AppStore'
        }
        request.post('sudrbmitloanOrder', postData)
          .then(() => {
            this.getOrderStatus()
          })
      } else {
        this.goAuthentication()
      }
    },
    goRepay (repayType) {
      const { ordrderID, padrymentID, fudrllrepaymentAmount, exdrhibitionrepaymentaAount } = this.loanInfo.bidrllDetails
      this.$router.push({
        path: '/repay',
        query: {
          repayType,
          amount: repayType === 1 ? fudrllrepaymentAmount : exdrhibitionrepaymentaAount,
          orderId: ordrderID,
          payID: padrymentID
        }
      })
    },
    selectLoan (item, index) {
      const loanStatus = item.loanStatus
      if (loanStatus) {
        if (index !== this.activeIndex) {
          this.activeIndex = index
          this.selectedLoanItem = item
          this.loanAmount = item.loanMoney
          this.loanTimers = item.loanTimers
        }
      } else {
        this.visible = true
      }
    },
    submit () {
      if (this.selectedLoanItem) {
        if (this.orderStatus === 0 || (this.orderStatus === 7 && this.loanInfo.redr_loan === 0)) {
          this.$router.push({
            path: '/authentication',
            query: {
              amount: this.selectedLoanItem.loanMoney
            }
          })
        } else if (this.orderStatus === 6 || (this.orderStatus === 7 && this.loanInfo.redr_loan === 1)) {
          this.getFeeDetail(this.selectedLoanItem, 2)
          this.feeVisible = true
        }
      } else {
        Toast('Silahkan pilih pinjaman terlebih dahulu')
      }
    },
    onOkFee () {
      if (this.flag) return
      this.flag = true
      const { lodranCash, lodranTimes } = this.feeDetail
      const postData = {
        lodranAmount: lodranCash,
        lodranPeriod: lodranTimes,
        widrthdrawType: this.withdrawType
      }
      request.post('widrthdrawCash', postData)
        .then(() => {
          this.feeVisible = false
          this.orderStatus = 1
        })
        .finally(() => {
          this.flag = false
        })
    },
    goAuthentication () {
      this.$router.push({
        path: '/authentication',
        query: {
          amount: this.loanInfo.lodranAmount
        }
      })
    },
    withdraw () {
      const postData = {
        loanMoney: this.loanInfo.lodranAmount,
        loanTimers: this.loanInfo.avdrailablePeriod
      }
      this.getFeeDetail(postData, 1)
      this.feeVisible = true
    },
    onRefresh () {
      this.getOrderStatus(true)
    },
    closeWebview () {
      window.webkit.messageHandlers.canGoBack.postMessage(1)
    }
  }
}
</script>

<style scoped lang="less">
.top-img {
  width: 100%;
}

.intro {
  line-height: 40px;
  margin-top: 20px;
  font-size: 28px;
  color: #333;
  text-align: center;
}

.deadline-title {
  line-height: 45px;
  margin-top: 18px;
  font-size: 32px;
  color: #666;
}

.time {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 199px;
  height: 216px;
  margin-top: 18px;
  border: 4px solid @theme-color;
  border-radius: 20px;

  .lock-icon {
    width: 74px;
    height: 103px;
    margin-top: 29px;
    margin-bottom: 5px;
  }

  .deadline-icon {
    width: 89px;
    height: 98px;
    margin-top: 26px;
    margin-bottom: 3px;
  }

  .rate-icon {
    width: 95px;
    height: 81px;
    margin-top: 30px;
    margin-bottom: 21px;
  }

  p {
    line-height: 42px;
    font-size: 30px;
    color: @theme-color;
    text-align: center
  }
}

.loan-status {
  min-height: 100vh;
  background-color: rgba(184,58,243,0.14);
}

.loan-status3 {
  .info {
    margin-top: 20px;
    margin-left: 30px;

    p {
      height: 40px;
      line-height: 40px;
      font-size: 28px;
      color: #333;

      &:nth-child(n+2) {
        margin-top: 18px;
      }
    }
  }
}

.loan-status4 {
  .detail {
    margin-top: 16px;
    font-size: 24px;
    color: #999;
    text-align: center;
  }
}

.loan-status5 {
  .repayment-content {
    height: 465px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 0 0 100px 100px;
    background-color: @theme-color
  }

  .p1 {
    padding-top: 55px;
    font-size: 36px;
    font-weight: 600;
    color: #FFF;
  }

  .p2 {
    margin-top: 11px;
    font-size: 26px;
    font-weight: 600;
    color: #F9F9F9;
  }

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }

  .overdue, .detail {
    font-size: 26px;
    font-weight: 600;
    color: #333;
  }

  .detail {
    text-decoration: underline;
  }

  .repayment-record {
    padding: 20px 24px 57px;
    border-radius: 8px 8px 0px 0px;
    margin-top: 22px;
    background-color: #FFF;
  }

  .label {
    line-height: 90px;
    border-radius: 12px;
    font-size: 28px;
    color: #333;
    text-align: center;
    background-color: #F9F9F9;

    &:nth-of-type(n+2) {
      margin-top: 12px;
    }
  }

  .full-repayment-btn {
    .submit-btn;
    width: 690px;
    margin-top: 484px;
  }

  .installment-repayment-btn {
    .submit-btn;
    width: 690px;
    margin-top: 24px;
    background-color: rgba(184,58,243,0.14);
  }
}

.loan-status6 {
  padding-left: 30px;
  padding-right: 30px;

  .loan-title {
    line-height: 45px;
    padding-top: 30px;
    font-size: 32px;
    color: #666;
  }

  .loan-amount-sort {
    display: flex;
    width: 100%;
    margin-top: 18px;
    overflow-x: scroll;

    & > div {
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      align-items: center;
      width: 199px;
      height: 216px;
      box-sizing: border-box;
      border-radius: 20px;

      &:nth-child(n+2) {
        margin-left: 32px;
      }

      &.active {
        border: 4px solid @theme-color;
      }

      p {
        line-height: 42px;
        font-size: 30px;
      }
    }

    .can-loan {
      background-color: #FFF;

      img {
        width: 74px;
        height: 103px;
        margin-top: 26px;
      }

      p {
        margin-block: 3px;
        color: @theme-color;
      }
    }

    .no-loan {
      background-color: #F9EDFE;

      img {
        width: 69px;
        height: 98px;
        margin-top: 25px;
      }

      p {
        margin-block: 9px;
        color: #FFF;
      }
    }
  }

  .submit {
    .submit-btn;
    width: 690px;
    margin-top: 128px;
  }

  .rise-limit-title {
    .dialog-title;
    padding-left: 24px;
    text-align: left;
  }

  .tip-title {
    margin-top: 32px;
    font-size: 30px;
    color: #333;
    text-align: center;
  }

  .tip {
    padding-left: 49px;
    padding-right: 49px;
    margin-top: 16px;
    font-size: 26px;
    color: #999;
    text-align: center;
  }

  .confirm-btn {
    .submit-btn;
    width: 634px;
    margin-top: 80px;
    margin-bottom: 84px;
    color: #FFF;
  }
}

.loan-status8 {
  .detail {
    margin: 18px 78px 0;
    font-size: 24px;
    color: #999;
    text-align: center;
  }

  .modify-btn {
    .submit-btn;
    width: 690px;
    margin-top: 300px;
  }
}

.loan-status9 {
  .detail {
    margin-top: 18px;
    font-size: 24px;
    color: #999;
    text-align: center;
  }

  .modify-btn {
    .submit-btn;
    width: 690px;
    margin-top: 300px;
  }

  .cancel-loan-dialog {
    background-color: transparent;
  }

  .cancel-loan-dialog-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding-top: 115.988%;
    background: url(../assets/img/cancel-loan-dialog-bg.png) no-repeat;
    background-size: cover;
    z-index: -1;
  }

  .cancel-loan-dialog-title {
    line-height: 42px;
    padding-top: 395px;
    font-size: 30px;
    color: #FFF;
    text-align: center;
  }

  .tip {
    margin: 18px 48px 0;
    font-size: 26px;
    color: #FFF;
    text-align: center;
  }

  .confirm-btn {
    .submit-btn;
    width: 546px;
    margin-top: 48px;
    margin-bottom: 124px;
  }
}

.loan-status12 {
  padding: 12px 30px 0;

  .withdraw-btn {
    .submit-btn;
    width: 100%;
    margin-top: 300px;
    margin-bottom: 98px;
  }
}

.fee-title {
  .dialog-title;
}

.fee-detail {
  margin: 32px 25px 168px;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 88px;
    line-height: 88px;
    padding-left: 24px;
    padding-right: 24px;

    &:nth-child(n+2) {
      margin-top: 6px;
    }

    p {
      font-size: 26px;
      color: #333;
    }
  }
}

.footer-btn {
  display: flex;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 45px;

  button {
    flex: 1;
    height: 88px;
    line-height: 88px;
    font-size: 30px;
    color: #FFF;
    border-radius: 8px;
  }

  .cancel-btn {
    background-color: #D9DADE;
  }

  .ok-btn {
    margin-left: 20px;
    background-color: @theme-color;
  }
}
</style>
